import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiConfig } from "../Config/ApiConfig";
import { AppContext } from "./AppContext";

const ResolutionContext = createContext();

const ResolutionProvider = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [noResolution, setNoResolution] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoadingResolution, setIsLoadingResolution] = useState(false);
  const [resolutions, setResolutions] = useState([]);
  const [regenerateCount, setRegenerateCount] = useState(null);
  const [copied, setCopied] = useState(false);

  const { appState, isAppStateLoaded } = useContext(AppContext);

  useEffect(() => {
    if (!isAppStateLoaded) return; // Only proceed if appState is loaded

    const fetchResolutions = async () => {
      setIsLoadingResolution(true);
      setNoResolution(false);
      setHasError(false);

      try {
        const resolutionData = {
          subdomain: appState?.orgId,
          ticketId: appState?.caseId,
          ticketStatus: appState?.caseStatus,
          feature_name: "topx",
        };

        const config = {
          headers: { "Access-Control-Allow-Origin": "*" },
          withCredentials: true,
        };

        const response = await axios.post(
          ApiConfig.getResolution,
          resolutionData,
          config
        );

        const ticketResolutions = response?.data?.ticket_base_resolutions;

        if (Object.keys(ticketResolutions)?.length === 0) {
          setErrorMessage("Nothing relevant found");
          setNoResolution(true);
        } else {
          setResolutions(
            Object.entries(ticketResolutions)?.map(([key, value]) => ({
              ...value,
              key: key,
            }))
          );
        }

        setRegenerateCount(
          response?.data?.topcx_remaining_intelligent_search_count
        );
      } catch (error) {
        setErrorMessage(
          error.response?.data?.detail?.masked_error ||
            "An unexpected error occurred."
        );
        setHasError(true);
      } finally {
        setIsLoadingResolution(false);
      }
    };

    fetchResolutions();
  }, [isAppStateLoaded]); // Dependency on isAppStateLoaded

  return (
    <ResolutionContext.Provider
      value={{
        hasError,
        setHasError,
        noResolution,
        setNoResolution,
        errorMessage,
        setErrorMessage,
        isLoadingResolution,
        setIsLoadingResolution,
        resolutions,
        setResolutions,
        regenerateCount,
        setRegenerateCount,
        copied,
        setCopied,
      }}
    >
      {children}
    </ResolutionContext.Provider>
  );
};

export { ResolutionContext, ResolutionProvider };
