import React, { createContext, useState, useEffect } from "react";
import queryString from "query-string";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    agentId: null,
    email: null,
    name: null,
    orgName: null,
    orgId: null,
    subdomain: null,
    role: null,
    caseId: null,
    caseStatus: null,
  });

  const [isAppStateLoaded, setIsAppStateLoaded] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    const updatedAppState = {
      agentId: parsed.agentId || null,
      email: parsed.email || null,
      name: parsed.name || null,
      orgName: parsed.orgName || null,
      orgId: parsed.orgId || null,
      subdomain: parsed.domainName?.split(".")?.[0] || "",
      role: parsed.profileName || null,
      caseId: parsed.caseId || null,
      caseStatus: parsed.caseStatus ? parsed.caseStatus.toLowerCase() : null,
    };

    setAppState(updatedAppState);

    // Check if all values in the appState are non-null
    const allValuesPopulated = Object.values(updatedAppState).every(
      (value) => value !== null && value !== ""
    );

    // Only mark as loaded if all values are non-null
    if (allValuesPopulated) {
      setIsAppStateLoaded(true);
    }
  }, []);

  return (
    <AppContext.Provider value={{ appState, isAppStateLoaded, setAppState }}>
      {children}
    </AppContext.Provider>
  );
};
