import React, { createContext, useState, useEffect, useContext } from "react";
import { AppContext } from "./AppContext";
import axios from "axios";
import { ApiConfig } from "../Config/ApiConfig";
const TicketContext = createContext();

const TicketProvider = ({ children }) => {
  const { appState, isAppStateLoaded } = useContext(AppContext);

  //navtab
  const [customerQuery, setCustomerQuery] = useState(null);
  const [customerSentiment, setCustomerSentiment] = useState(null);

  //performance modal
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const handleShowPerformanceModal = () => setShowPerformanceModal(true);
  const handleClosePerformanceModal = () => {
    setShowPerformanceModal(false);
    setNotificationTicketQA(false);
    setActiveTabPerformanceModal("Analytics");
  };

  const [activeTabPerformanceModal, setActiveTabPerformanceModal] =
    useState("Analytics");

  const handleTabSelectPerformanceModal = (key) => {
    setActiveTabPerformanceModal(key);
  };

  // summary modal
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const handleOpenSummaryModal = () => setShowSummaryModal(true);
  const handleCloseSummaryModal = () => setShowSummaryModal(false);

  // notification

  const [notifications, setNotifications] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAppStateLoaded) return;

      const apiData = {
        user_id: appState?.email,
      };

      try {
        setIsLoadingData(true);
        const response = await axios.post(
          ApiConfig.notificationGetAll,
          apiData,
          {
            headers: { "Access-Control-Allow-Origin": "*" },
            withCredentials: true,
          }
        );
        setNotifications(response.data);
      } catch (error) {
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchData();
  }, [appState, isAppStateLoaded]);

  // QA
  // ticketQA
  const [ticketID, setTicketID] = useState("");
  const [notificationTicketQA, setNotificationTicketQA] = useState(false);

  return (
    <TicketContext.Provider
      value={{
        customerQuery,
        setCustomerQuery,
        customerSentiment,
        setCustomerSentiment,
        //performance modal
        showPerformanceModal,
        handleShowPerformanceModal,
        handleClosePerformanceModal,
        activeTabPerformanceModal,
        handleTabSelectPerformanceModal,
        // summary modal
        showSummaryModal,
        handleOpenSummaryModal,
        handleCloseSummaryModal,

        // notifications
        isLoadingData,
        notifications,
        setNotifications,
        // QA
        // ticket QA
        ticketID,
        setTicketID,
        notificationTicketQA,
        setNotificationTicketQA,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

export { TicketContext, TicketProvider };
