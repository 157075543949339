import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import axios from "axios";
import { ApiConfig } from "../Config/ApiConfig";
import { AppContext } from "./AppContext";
const SearchXContext = createContext();

const SearchXProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [resolutions, setResolutions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [noResolution, setNoResolution] = useState(false);
  const [isLoadingResolution, setIsLoadingResolution] = useState(false);
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("Search your query...");
  const [entitySuggestions, setEntitySuggestions] = useState([]);
  const [aspectSuggestions, setAspectSuggestions] = useState([]);
  const [searchTagsEntity, setSearchTagsEntity] = useState([]);
  const [searchTagsAspect, setSearchTagsAspect] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const { appState } = useContext(AppContext);

  const fetchData = useCallback(
    async ({ query }) => {
      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };
      const searchData = {
        query: query,
        ticketId: appState?.caseId,
      };
      try {
        setIsLoadingResolution(true);
        setNoResolution(false);
        setHasError(false);

        const response = await axios.post(
          ApiConfig.querySearch,
          searchData,
          config
        );
        if (response.data?.length === 0) {
          const message = "Nothing relevant found";
          setErrorMessage(message);
          setNoResolution(true);
        } else {
          setResolutions(response.data);
          setNoResolution(false);
        }
      } catch (error) {
        const message =
          error.response?.data?.detail?.masked_error ||
          "An unexpected error occurred.";
        setErrorMessage(message);
        setHasError(true);
      } finally {
        setIsLoadingResolution(false);
      }
    },
    [appState?.caseId, title]
  );

  useEffect(() => {
    const fetchResolutions = async () => {
      setIsLoadingResolution(true);
      setNoResolution(false);
      setHasError(false);
      try {
        const resolutionData = {
          subdomain: appState?.orgId,
          ticketId: appState?.caseId,
          ticketStatus: appState?.caseStatus,
          feature_name: "searchx",
        };

        const config = {
          headers: { "Access-Control-Allow-Origin": "*" },
          withCredentials: true,
        };

        const response = await axios.post(
          ApiConfig.getResolution,
          resolutionData,
          config
        );

        setTitle(response?.data?.topcx_customer_query);
        await fetchData({ query: response?.data?.topcx_customer_query });
      } catch (error) {
        const message =
          error.response?.data?.detail?.masked_error ||
          "An unexpected error occurred.";
        setErrorMessage(message);
        setHasError(true);
      } finally {
        setIsLoadingResolution(false);
      }
    };
    fetchResolutions();
  }, [appState?.caseId, appState?.caseStatus, appState?.orgId]);

  return (
    <SearchXContext.Provider
      value={{
        fetchData,
        errorMessage,
        setErrorMessage,
        resolutions,
        setResolutions,
        hasError,
        setHasError,
        noResolution,
        setNoResolution,
        isLoadingResolution,
        setIsLoadingResolution,
        copied,
        setCopied,
        title,
        setTitle,
        entitySuggestions,
        setEntitySuggestions,
        aspectSuggestions,
        setAspectSuggestions,
        searchTagsEntity,
        setSearchTagsEntity,
        searchTagsAspect,
        setSearchTagsAspect,
        inputValue,
        setInputValue,
      }}
    >
      {children}
    </SearchXContext.Provider>
  );
};

export { SearchXContext, SearchXProvider };
