import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { ApiConfig } from "../Config/ApiConfig";
import { AppContext } from "./AppContext";

const ChatbotContext = createContext();

const ChatbotProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [queryCountLeft, setQueryCountLeft] = useState(0);
  const [maxQueryCount, setMaxQueryCount] = useState(0);
  const [chatID, setChatID] = useState("");
  const [copied, setCopied] = useState("");
  const [download, setDownload] = useState(false);
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [userScrolledUp, setUserScrolledUp] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);

  const { appState, isAppStateLoaded } = useContext(AppContext);

  useEffect(() => {
    if (!isAppStateLoaded) return; // Only proceed if appState is loaded

    const fetchChats = async () => {
      const chatData = {
        subdomain: appState?.orgId,
        email: appState?.email,
      };
      const config = {
        headers: { "Access-Control-Allow-Origin": "*" },
        withCredentials: true,
      };
      try {
        setIsLoadingMessages(true);
        const response = await axios.post(ApiConfig.getChats, chatData, config);
        const chatDetails = response?.data?.data?.details;
        setQueryCountLeft(chatDetails?.query_count_left);
        setMaxQueryCount(chatDetails?.max_query_count);
        setChatID(chatDetails?.chat_id_key);
        setMessages(chatDetails?.chat_id[chatDetails?.chat_id_key]?.qna || []);
      } catch (error) {
          setError(true);
          if (error?.response) {
            if (error?.response?.status === 500) {
              setApiError("Internal Server Error. Please try again later.");
            } else {
              setApiError(
                error?.response?.data?.detail?.masked_error ||
                  "An error occurred."
              );
            }
          } else {
            setApiError("Network error. Please check your connection.");
          }
        } finally {
          setIsLoadingMessages(false);
        }
    };

    fetchChats();
  }, [isAppStateLoaded]); // Dependency on isAppStateLoaded

  return (
    <ChatbotContext.Provider
      value={{
        messages,
        setMessages,
        userInput,
        setUserInput,
        charCount,
        setCharCount,
        queryCountLeft,
        setQueryCountLeft,
        maxQueryCount,
        setMaxQueryCount,
        chatID,
        setChatID,
        copied,
        setCopied,
        download,
        setDownload,
        error,
        setError,
        apiError,
        setApiError,
        isLoadingMessages,
        setIsLoadingMessages,
        isLoadingResponse,
        setIsLoadingResponse,
        userScrolledUp,
        setUserScrolledUp,
        isStreaming,
        setIsStreaming,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};

export { ChatbotContext, ChatbotProvider };
