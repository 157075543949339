/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiConfig } from "../Config/ApiConfig";
import Loader from "./Loader/Loader";
import { AppContext } from "../Context/AppContext";

export default function LandingPage() {
  const { appState, isAppStateLoaded } = useContext(AppContext);

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAppStateLoaded) {
      const auth = async () => {
        setIsLoadingLogin(true);

        const authData = {
          subdomain: appState?.orgId,
          email: appState?.email,
          role: appState?.role,
          source: "resolution_screen",
          ticketStatus: appState?.caseStatus,
        };

        const config = {
          headers: { "Access-Control-Allow-Origin": "*" },
          withCredentials: true,
        };

        try {
          const response = await axios.post(ApiConfig.login, authData, config);
          if (response.data.oauth) {
            navigate("/oauthnotfound");
          } else {
            navigate("/navtab");
          }
        } catch (error) {
          if (error.response) {
            if (error.response.status === 401) {
              navigate("/accessdenied");
            } else if (error.response.status === 400) {
              navigate("/navtab");
              // navigate("/solvedticketerror");
            } else {
              navigate("/error");
            }
          } else {
            navigate("/error");
          }
        } finally {
          setIsLoadingLogin(false);
        }
      };
      auth();
    }
  }, [navigate, isAppStateLoaded, appState]);
  return (
    <div style={{ height: "100vh" }}>
      {isLoadingLogin ? <Loader /> : <div />}
    </div>
  );
}
