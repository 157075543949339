import React, { useState, useEffect, useContext, useRef } from "react";
import { Card, Form, Container, Row, Col, InputGroup } from "react-bootstrap";
import { ApiConfig } from "../../../../Config/ApiConfig";
import { AppContext } from "../../../../Context/AppContext";
import axios from "axios";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";

export default function QaReviews({
  reviews,
  ticketId,
  currentUser,
  ticketData,
  error,
}) {
  const { appState, isAppStateLoaded } = useContext(AppContext);
  const [qaReview, setQaReview] = useState(reviews);
  const [reviewText, setReviewText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const messagesEndRef = useRef(null);

  // Function to scroll to the bottom of the chat container
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when component mounts or qaReview changes
  }, [qaReview]);

  const handleReviewChange = (e) => {
    setReviewText(e.target.value);
  };

  const getAllEmailsFromChats = (chats) => {
    const emails = chats.map((chat) => chat.sender_id);
    return [...new Set(emails)]; // Remove duplicate emails using Set
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents new line
      handleReviewSubmit(); // Call the submit function
    }
  };

  const handleReviewSubmit = async () => {
    if (!isAppStateLoaded) return;

    const allEmails = getAllEmailsFromChats(qaReview?.chats || []);

    const apiData = {
      ticket_id: ticketId,
      logged_in_user: appState?.email,
      sender_name: appState?.name,
      comment: reviewText,
      ticket_agent_email: ticketData?.agent_info?.email,
      ticket_agent_name: ticketData?.agent_info?.name,
      ticket_url: ticketData?.conversations?.ticket_url,
      all_agents: allEmails,
    };

    const config = {
      headers: { "Access-Control-Allow-Origin": "*" },
      withCredentials: true,
    };

    try {
      setIsLoading(true);
      const response = await axios.post(
        ApiConfig.ticketQAReview,
        apiData,
        config
      );
      toast.success("Review submitted successfully!");
      setQaReview(response.data);
    } catch (error) {
      toast.error(
        error.response?.data?.detail ? (
          <div>
            <strong>#{error.response.data.detail.error_code}</strong>
            &nbsp;&nbsp;
            {error.response.data.detail.masked_error}
          </div>
        ) : (
          "Something went wrong. Please try again later."
        )
      );
    } finally {
      setReviewText("");
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const renderMessages = (messages, email) => {
    return messages.map((msg, index) => {
      const isCurrentEmail = msg.sender_id === email;
      const alignmentClass = isCurrentEmail
        ? "justify-content-end"
        : "justify-content-start";

      const text = isCurrentEmail ? "" : "text-white";
      const background = isCurrentEmail ? "#eeeeee99" : "#3156d3";
      const textAlign = isCurrentEmail ? "text-end" : "text-start";

      return (
        <Row key={index} className={`my-3 d-flex ${alignmentClass}`}>
          <Col xs="auto">
            <div
              className={`d-flex align-items-center ${
                isCurrentEmail ? "" : "flex-row-reverse"
              }`}
            >
              <div>
                <div className={`${textAlign}`} style={{ fontSize: "14px" }}>
                  <FaUser size={10} className="me-2" />
                  <strong>{msg.sender_name || "User Name"}</strong>
                </div>
                <div
                  className={`text-muted ${textAlign}`}
                  style={{ fontSize: "10px" }}
                >
                  {formatDate(msg.timestamp)}
                </div>
                <div
                  className={`p-2 mt-2 ${text}`}
                  style={{
                    borderRadius: "10px",
                    maxWidth: "550px",
                    fontSize: "14px",
                    backgroundColor: background,
                  }}
                >
                  <div>{msg.message}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    });
  };

  if (!ticketId) {
    return (
      <Card className="border-0 shadow">
        <Card.Header
          className="p-2 bg-light border-0 fw-bold"
          style={{ fontSize: "12px" }}
        >
          QA Review
        </Card.Header>
        <Card.Body>
          <h5 className="text-muted text-center my-3">No Data Found</h5>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className="border border-0 shadow">
      <Card.Header
        className="p-2 bg-light border border-0 fw-bold"
        style={{ fontSize: "12px" }}
      >
        QA Review
      </Card.Header>
      <Card.Body className="d-flex flex-column">
        <Container
          className="rounded rounded-3"
          style={{
            width: "100%",
            maxHeight: "350px",
            overflowY: "auto",
          }}
        >
          {qaReview &&
            qaReview.chats &&
            qaReview.chats.length > 0 &&
            renderMessages(qaReview.chats, currentUser?.email)}
          <div ref={messagesEndRef} />
        </Container>
        <InputGroup className="border border-1 rounded rounded-3 shadow mt-2">
          <Form.Control
            as="textarea"
            rows={2}
            value={reviewText}
            onChange={handleReviewChange}
            placeholder="Enter your comment here..."
            className="border border-0"
            style={{ resize: "none", overflow: "auto" }}
            onKeyDown={handleKeyDown}
            disabled={isLoading || error}
          />
          <button
            className="btn bg-white shadow-none border border-0"
            type="button"
            onClick={handleReviewSubmit}
            disabled={isLoading || error}
          >
            <div
              className="mb-1"
              style={{
                color: "#3156d3",
              }}
            >
              <IoMdSend className="fs-5" />
            </div>
          </button>
        </InputGroup>
      </Card.Body>
    </Card>
  );
}
